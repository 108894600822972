<template>
<CContainer>
  <CRow>
      <CCol col="12">
        <CCard>
          <CCardHeader>
            <CRow>
              <CCol col="2">
                <router-link :to="'/mailing/grupos'">
                  <CButton
                    color="dark"
                    variant="outline"
                    square
                    size="sm">
                    Atras 
                  </CButton>
                </router-link>
              </CCol>
              <CCol col="10">
                 <h3>Integrantes del Grupo Maling {{grupo.nombre}}</h3>
              </CCol>
            </CRow>
          </CCardHeader>
        </CCard>
      </CCol>
   </CRow>
   <CRow>
      <CCol col="6">
        <CCard>
          <CCardHeader>
            <h5 class="text-center">Crear Integrante</h5>
          </CCardHeader>
          <CCardBody>
                <template v-for="(name, key) in radioNamesMetodoEnvio">
                <CRow form class="form-group" :key="name">
                  <CCol sm="12">
                    {{name}}
                  </CCol>
                  <CInputRadioGroup
                    class="col-12"
                    :options="optionsMetodoEnvio"
                    :custom="true"
                    :checked="`Option ${key}`"
                    :inline="key % 2 === 0"
                    :checked.sync="metodo_envido_checked_radio"
                  />
                </CRow>
              </template>
                <template v-if="changeRadioMetodoEnvio == 1">
                <hr>
               <!--<CRow>
                 <CCol col="12">
                      <h5></h5>
                 </CCol>
              </CRow>-->
              <CForm validate @submit.prevent="crearNuevoContactoIndividual()">
                <CRow>
                 <CCol col="12">
                    <div v-for="(course, index) in items_form_individual" :key="index">
                        <div v-if="course.typeField === 'email'">
                          <CInput
                            :label="getNameField(course.field)"
                            :placeholder="getNameField(course.field)"
                            autocomplete="off"
                            required
                            v-model="course.value"
                            @keyup="validationsFieldInput(course.typeField,$event)"
                          />
                        </div>
                        <div v-else-if="course.typeField === 'string'">
                          <CInput
                            :label="getNameField(course.field)"
                            :placeholder="getNameField(course.field)"
                            autocomplete="off"
                            v-model="course.value"
                            @keyup="validationsFieldInput(course.typeField,$event)"
                          />
                        </div>
                        <div v-else-if="course.typeField === 'number'">
                          <CInput
                            :label="getNameField(course.field)"
                            :placeholder="getNameField(course.field)"
                            autocomplete="off"
                            v-model.number="course.value"
                            @keyup="validationsFieldInput(course.typeField,$event)"
                          />
                        </div>
                        <div v-else-if="course.typeField === 'decimal'">
                          <CInput
                            :label="getNameField(course.field)"
                            :placeholder="getNameField(course.field)"
                            autocomplete="off"
                            v-model="course.value"
                            @keyup="validationsFieldInput(course.typeField,$event)"
                          />
                        </div>
                    </div>
                     <hr>
                     <div class="text-center">
                         <CButton size="sm" color="success" type="submit">
                            Crear Contacto
                        </CButton>
                     </div>
                 </CCol>
              </CRow>
              </CForm>
              </template>  
              <template v-if="changeRadioMetodoEnvio == 2">
                <hr>
                <CForm validate @submit.prevent="crearNuevoContactosArchivos">
                  <CRow>
                  <CCol col="12">
                      <label for="">Cargar una base de datos formato Excel o CSV con las columnas creadas previamente en el grupo </label>
                      <hr>
                  </CCol>
                  <CCol col="12">
                       <label for="">Seleccione un Archivo</label>
                       <input type="file" name="" id="" v-on:change="onChangeArchivoGrupo" :key="fileInputKey"  accept=".xlsx, .xls, .csv">
                        <hr>
                  </CCol>
                  <hr>
                  <CCol col="12">
                      <label for="">
                          <strong>- La primera Columna (A) debe ser siempre el email (Obligatorio)</strong><br>
                          <strong>- La proximas Columnas deben ser ubicadas en el mismo orden en que fueron creadas (Opcional)</strong>
                      </label>
                      <hr>
                  </CCol>
                  <CCol col="12" class="text-center">
                      <CButton size="sm" color="success" type="submit">
                          Cargar Archivo
                      </CButton>
                  </CCol>
                </CRow>
              </CForm>
              </template>  
          </CCardBody>
        </CCard>
      </CCol>
      <CCol col="6">
         <CCard>
          <CCardHeader>
            <h5>Resumen ultimo cargue por archivo</h5>
          </CCardHeader>
          <CCardBody>
              <CListGroup>
                <CListGroupItem>Fecha Cargue : <strong>{{detalleCargue.date}}</strong></CListGroupItem>
                <CListGroupItem>Registros Insertados: <strong>{{detalleCargue.insert}}</strong></CListGroupItem>
                <CListGroupItem>Registros Errados ó duplicados: <strong>{{detalleCargue.error}}</strong>  <CButton v-if="detalleCargue.name_file_error !== ''" size="sm" color="info" @click="descargarArchivoError(detalleCargue.name_file_error)"> Descargar Errados ó duplicados </CButton></CListGroupItem>
                <CListGroupItem>Registros Actualizados: <strong>{{detalleCargue.updated}}</strong></CListGroupItem>
                <!--<CListGroupItem v-if="detalleCargue.name_file_error !== ''">Archivo Errores: <CButton size="sm" color="info" @click="descargarArchivoError(detalleCargue.name_file_error)"> Descargar </CButton></CListGroupItem>-->
              </CListGroup>
          </CCardBody>
        </CCard>
      </CCol>
   </CRow>
     <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
           <CRow class="justify-content-center">
              <CCol col="6">
                <h5 class="text-center">Cantidad de Integrantes creados: {{$formatterNumeroTipoCreditos(file_all_grupo.cantidad)}}</h5>
              </CCol>
           </CRow>
           <CRow class="justify-content-right">
              <CCol col="6">
                <CButton size="sm" class="text-center" color="success" @click="generarReporteIntegrantes()" v-if="file_all_grupo.cantidad > 0">
                    Descargar {{$formatterNumeroTipoCreditos(file_all_grupo.cantidad)}} Integrantes creados </h5>
                 </CButton>
              </CCol>
           </CRow>
        </CCardHeader>
        <CCardBody>
          <CDataTable
            hover
            striped
            :items="items"
            :fields="fields"
            :items-per-page="10"
            :pagination="{ doubleArrows: false, align: 'center'}"
            column-filter
            @page-change="pageChange"
          >
          <template #created_at="{item}">
              <td>
                 {{formatoFecha(item.created_at)}}
              </td>
          </template>
           <template #show_details="{item, index}">
                <td class="py-2">
                  <CButton
                    color="primary"
                    variant="outline"
                    square
                    size="sm"
                    @click="toggleDetails(item, index)"
                  >
                    {{Boolean(item._toggled) ? 'Ocultar' : 'Ver'}}
                  </CButton>
                </td>
                <td class="py-2">
                  <CButton size="sm" color="danger" @click="abrirmodalEliminarGrupo(item)">
                        Eliminar
                  </CButton>
                </td>
              </template>
              <template #details="{item}">
                <CCollapse :show="Boolean(item._toggled)" :duration="collapseDuration">
                  <CCardBody> 
                      <p class="text-muted" v-for="(field,index) in JSON.parse(item.campos_personalizados)" :key="index">
                        {{index}} : {{field}}
                      </p>
                  </CCardBody>
                </CCollapse>
              </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
  <div>
          <CToaster :autohide="5000" class="d-inline-block">
             <template v-for="toast in mostrarNotificacion">
                <CToast
                  :key="'toast'+toast"
                  :show="true"
                  color="success"
                  header="Notificacion Exitosa"
                >
                  {{mensaje_success}}
                </CToast>
              </template>   
              <template v-for="toast1 in mostrarNotificacionAlerta">
                <CToast
                  :key="'toast1'+toast1"
                  :show="true"
                  color="warning"
                  header="Notificacion Alerta"
                >
                  {{mensaje_warning}}
                </CToast>
              </template>
              <template v-for="toast2 in mostrarNotificacionError">
                <CToast
                  :key="'toast2'+toast2"
                  :show="true"
                  color="danger"
                  header="Notificacion Alerta"
                >
                  Error Comunicarse Con Soporte
                </CToast>
              </template>  
          </CToaster>
          <CModal
            :show.sync="modal_eliminar_grupo"
            :no-close-on-backdrop="true"
            :centered="true"
            title="Modal title 2"
            size="sm"
            color="dark"
          >
            <CRow>
              <CCol col="12">
                Usted esta Apunto de Eliminar el Integrante : <strong>{{form_eliminar_integrante.email}}</strong>
              </CCol>
            </CRow>
            <template #header>
              <h6 class="modal-title">Eliminar Integrante</h6>
              <CButtonClose @click="modal_eliminar_grupo = false" class="text-white"/>
            </template>
            <template #footer>
              <CButton @click="modal_eliminar_grupo = false" color="danger">Cancel</CButton>
              <CButton  color="success" @click="eliminarIntegrantePorId()">Aceptar</CButton>
            </template>
          </CModal>
          <PreviewDataGrupo :openModal="modal_preview_grupo" :dataPreview="dataPreview" @close-modal="closeModal"/>
  </div>
</CContainer>

</template>


<script>
import { val } from '../../../custom_modules/vue-datamaps/src/components/helper';
import EventBus from '../../../modules/bus';
import PreviewDataGrupo from './PreviewDataGrupo.vue'
export default {
  name: 'IntegrantesGrupos',
  components: { PreviewDataGrupo },
  data () {
    return {
      items: [],
      fields: [
        { key: 'email', label: 'Email', _classes: 'font-weight-bold' },
        { key: 'created_at',label:'Fecha Creado' },
        { key: 'show_details',label:'Acciones',
          _style: { width: '1%' },
          sorter: false,
          filter: false 
        }
      ],
      activePage: 1,
      collapseDuration: 0,
      nombre_grupo:'',
      mostrarNotificacion: 0,
      mostrarNotificacionAlerta: 0,
      mostrarNotificacionError:0,
      mensaje_warning:'Debe Completar Los Datos Vacio',
      mensaje_success:'Grupo Creado Correctamente.',
      modal_eliminar_grupo:false,
      form_eliminar_integrante:{
        celular:'',
        id:'',
      },
      optionsMetodoEnvio: [{label: 'Individual',value:'individual'},{label: 'Cargue Archivo',value:'archivo'}],
      radioNamesMetodoEnvio: ['Selecciona el Metodo de Crear Contactos'],
      metodo_envido_checked_radio:'individual',
      itemsPais: [{value:'57',label:'Colombia (57)'},{value:'58',label:'Venezuela (58)'}],
      formCrearContactosArchivo:{
        archivo:null
      },
      grupo:{
        nombre: '',
        id: '',
      },
      file_all_grupo:{},
      items_form_individual:[],
      fileInputKey:0,
      descargado:null,
      filtro_celular:'',
      flag:false,
      dataPreview:{},
      modal_preview_grupo:false,
      contadorFlag:0,
      detalleCargue:{
        date:'',
        error:'',
        insert:'',
        updated:'',
        name_file_error:''
      },
      contadorFlagRefresh:0,
      id_process:0,
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    }
  },
  methods: {
    getBadge (status) {
      switch (status) {
        case 1: return 'success'
        case 0: return 'danger'
      }
    },
    rowClicked (item, index) {
      this.$router.push({path: `users/${index + 1}`})
    },
    pageChange (val) {
      this.$router.push({ query: { page: val }})
    },
    getInitGrupoIntegrantes(){
        const id = this.$route.params.id;
        //this.$store.dispatch('auth/loading_http','visible');
        this.$http.get(this.$httpUrlMail+'/mail/grupos/'+id,{ withCredentials: false }).then(response => {
             this.grupo.nombre = response.data.nombre_grupo;
             this.grupo.id = response.data.id_grupo;
             this.file_all_grupo = response.data;
             this.items_form_individual = JSON.parse(response.data.campos_personalizados);
             try {
               let detalleJson = JSON.parse(response.data.detalle);
               this.detalleCargue.date = detalleJson.date;
               this.detalleCargue.insert = detalleJson.success;
               this.detalleCargue.updated = detalleJson.updated;
               this.detalleCargue.error = detalleJson.error;
               if(this.calcularValidacionFechas(detalleJson.date.split(' ')[0])) {
                 this.detalleCargue.name_file_error = detalleJson.name_file_error;
               }
             } catch (error) {
              
             }
        },{ withCredentials: false }).catch(()=>{
             
        })//.finally(() =>this.$store.dispatch('auth/loading_http','hidden'));
        this.$store.dispatch('auth/loading_http','visible');
        this.$http.get(this.$httpUrlMail+'/mail/grupos/integrantes/'+id,{ withCredentials: false }).then(response => {
             this.items = response.data;
             this.items.map((item,id) => {  
              if (id%2 == 0) {
                item['_classes'] = 'table-now'; 
              }
              item['_toggled'] = false; 
              return item;
              })
             //this.items = this.items.map((item, id) => { return {...item, id}})
        }).catch(()=>{
             
        }).finally(() =>this.$store.dispatch('auth/loading_http','hidden'));
    },
    searchIntegrantes(search){
      const id = this.$route.params.id;
      this.$store.dispatch('auth/loading_http','visible');
        this.$http.post(this.$httpUrlMail+'/mail/grupos/integrantes/search',{
          //params: {
            id:id,
            search:search
          //}
        },{ withCredentials: false }).then(response => {
             this.items = response.data;
             this.items.map((item,id) => {  
              if (id%2 == 0) {
                item['_classes'] = 'table-now';
              }
              item['_toggled'] = false; 
              return item;
              })
             //this.items = this.items.map((item, id) => { return {...item, id}})
        }).catch(()=>{
             
        }).finally(() =>this.$store.dispatch('auth/loading_http','hidden'));
    },
    getGrupoIntegrantes(){
        this.$store.dispatch('auth/loading_http','visible');
        const id = this.$route.params.id;
        this.$http.get(this.$httpUrlMail+'/mail/grupos/'+id,{ withCredentials: false }).then(response => {
             this.grupo.nombre = response.data.nombre_grupo;
             this.grupo.id = response.data.id_grupo;
             this.file_all_grupo = response.data;
             this.items_form_individual = JSON.parse(response.data.campos_personalizados);
             try {
               let detalleJson = JSON.parse(response.data.detalle);
               this.detalleCargue.date = detalleJson.date;
               this.detalleCargue.insert = detalleJson.success;
               this.detalleCargue.updated = detalleJson.updated;
               this.detalleCargue.error = detalleJson.error;
               if(this.calcularValidacionFechas(detalleJson.date.split(' ')[0])) {
                 this.detalleCargue.name_file_error = detalleJson.name_file_error;
               }
             } catch (error) {
              
             }
        },{ withCredentials: false }).catch(()=>{
             
        }).finally(() =>this.$store.dispatch('auth/loading_http','hidden'));
        this.$store.dispatch('auth/loading_http','visible');
        this.$http.get(this.$httpUrlMail+'/mail/grupos/integrantes/'+id,{ withCredentials: false }).then(response => {
             this.items = response.data;
             this.items.map((item,id) => {  
              if (id%2 == 0) {
                item['_classes'] = 'table-now'; 
              }
              item['_toggled'] = false; 
              return item;
              })
             //this.items = this.items.map((item, id) => { return {...item, id}})
        }).catch(()=>{
             
        }).finally(() =>this.$store.dispatch('auth/loading_http','hidden'));
        /*const id = this.$route.params.id;
        //this.$store.dispatch('auth/loading_http','visible');
        this.$http.get(this.$httpUrlMail+'/mail/grupos/integrantes/'+id,{ withCredentials: false }).then(response => {
             this.items = response.data;
             this.items.map((item,id) => {  
                if (id%2 == 0) {
                  item['_classes'] = 'table-now'; 
                }
                item['_toggled'] = false; 
                return item;
              })
              this.items_form_individual = JSON.parse(response.data.campos_personalizados);
              
             //this.items = this.items.map((item, id) => { return {...item, id}})
        }).catch(()=>{
             
        })//.finally(() =>this.$store.dispatch('auth/loading_http','hidden'));
        //this.$store.dispatch('auth/loading_http','visible');
        this.$http.get(this.$httpUrlMail+'/mail/grupos/'+id,{ withCredentials: false }).then(response => {
             this.grupo.nombre = response.data.nombre_grupo;
             this.grupo.id = response.data.id_grupo;
             this.file_all_grupo = response.data;
             try {
               let detalleJson = JSON.parse(response.data.detalle);
               this.detalleCargue.date = detalleJson.date;
               this.detalleCargue.insert = detalleJson.success;
               this.detalleCargue.updated = detalleJson.updated;
               this.detalleCargue.error = detalleJson.error;
               if(this.calcularValidacionFechas(detalleJson.date.split(' ')[0])) {
                 this.detalleCargue.name_file_error = detalleJson.name_file_error;
               }
             } catch (error) {
              
             }
        }).catch(()=>{
             
        })//.finally(() =>this.$store.dispatch('auth/loading_http','hidden'));*/
    },
    generarReporteIntegrantes(){
      this.$store.dispatch('auth/loading_http','visible');
      let id = this.grupo.id;
      this.descargado = null;
        this.$http.get(this.$httpUrlMail+'/mail/integrantes/reporte/'+id,{ withCredentials: false }).then(response => {
             let data = response.data;
             if (data == 'OK') {
                  this.mostrarNotificacion++
                  this.mensaje_success='El Reporte Se Esta Generando Correctamente Por Favor Espere Para que se Descargue de Inmediato.'
                  this.descargado = null;
                  let timerId = setInterval(() => this.getreporte(), 5000);
                  let time2 = setInterval(() => {
                    if (this.descargado != null) {
                      clearInterval(timerId); 
                      clearInterval(time2); 
                      this.descargado = null;
                    }
                  }, 2000);
                  // después de 5 segundos parar
                  setTimeout(() => { clearInterval(timerId);clearInterval(time2);}, 500000);
             }else{
                  this.mostrarNotificacionAlerta++
                  this.mensaje_warning='No Fue Posible Descargar El Reporte'
             }
        }).catch(()=>{
             
        })//.finally(() =>this.$store.dispatch('auth/loading_http','hidden'));
    },
    getreporte(){
      const id = this.$route.params.id;
        //this.$store.dispatch('auth/loading_http','visible');
        this.$http.get(this.$httpUrlMail+'/mail/grupos/'+id,{ withCredentials: false }).then(response => {
          let data = response.data;
          //console.log(data);
          //console.log(this.descargado);
          if (data.nombre_archivo != null) {
              if (this.descargado == null) {
                  let nombre = data.nombre_archivo; 
                  var url = this.$httpUrlMail + `/mail/reporte/descargar/${nombre}`;
                  window.open(url, '_blank');
                  this.descargado = data.nombre_archivo;
                  this.$store.dispatch('auth/loading_http','hidden')
              }
          }
        }).catch(()=>{
             
        })
    },
    toggleDetails (item,index) {
      this.$set(this.items[index], '_toggled', !item._toggled)
      this.collapseDuration = 300
      this.$nextTick(() => { this.collapseDuration = 0})
    },
    eliminarIntegrantePorId(){
      this.modal_eliminar_grupo = false
      this.$store.dispatch('auth/loading_http','visible');
      this.$http.delete(this.$httpUrlMail+'/mail/grupos/integrantes/'+this.form_eliminar_integrante.id,{ withCredentials: false }).then(response =>{
        let data = response.data;
        if (data == "OK") {
            let nombre = this.form_eliminar_integrante.email;
            this.form_eliminar_integrante.id = ''
            this.form_eliminar_integrante.email = ''
            this.mostrarNotificacion ++;
            this.getGrupoIntegrantes();
            this.mensaje_success=`Integrante ${nombre} Eliminado Correctamente.`,
            setTimeout(() => {this.mensaje_success='Grupo Creado Correctamente.'},6000)
        }else{
              let mensajeError = '';
              let dataError = response.data;
              for (const key in dataError) {
                if (Object.hasOwnProperty.call(dataError, key)) {
                  const element = dataError[key];
                  //mensajeError += `${element.mensaje} \n`
                  for (const iterator of element) {
                    mensajeError += `${iterator} \n`
                  }
                }
              }
              this.mensaje_warning = mensajeError
              this.mostrarNotificacionAlerta += 1;
        }
      }).catch(error=>{this.mostrarNotificacionError ++;}).finally(() =>this.$store.dispatch('auth/loading_http','hidden'))
    },
    formatoFecha(fecha){
      let fechaformato = fecha.split('.')[0];
      return fechaformato.replace('T',' ');
    },
    abrirmodalEliminarGrupo(item){
      this.modal_eliminar_grupo = true;
      this.form_eliminar_integrante.email = item.email;
      this.form_eliminar_integrante.id = item.id_integrante;
    },
    crearNuevoContactoIndividual(evt){
      if (this.validationsFieldInputEmails(this.items_form_individual) == false) {
        this.mensaje_warning = 'Los campos que son de tipo correos no son validos';
        this.mostrarNotificacionAlerta += 1
        return;
      }
      const id = this.$route.params.id;
      evt = (evt) ? evt : window.event;
      evt.target.disabled = true;
      this.$store.dispatch('auth/loading_http','visible');
      this.$http.post(this.$httpUrlMail+'/mail/grupos/integrantes',{
        id_grupo:id,
        campos:this.items_form_individual,
      },{ withCredentials: false }).then(response =>{
        let data = response.data;
        if (data == "OK") {
            this.mensaje_success='Contacto Individual Creado Correctamente.'
            evt.target.disabled = false;
            this.mostrarNotificacion ++;
            this.getGrupoIntegrantes();
        }else if(data == "ERROR"){
              this.mensaje_warning = 'Ocurrio un Error Creando el Contacto Individual'
              this.mostrarNotificacionAlerta += 1;
              evt.target.disabled = false;
        }else if(data == "DUPLICADO"){
              this.mensaje_warning = 'El Email Ya Existe en el Grupo'
              this.mostrarNotificacionAlerta += 1;
              evt.target.disabled = false;
        }else{
              let mensajeError = '';
              let dataError = response.data;
              for (const key in dataError) {
                if (Object.hasOwnProperty.call(dataError, key)) {
                  const element = dataError[key];
                  //mensajeError += `${element.mensaje} \n`
                  for (const iterator of element) {
                    mensajeError += `${iterator} \n`
                  }
                }
              }
              this.mensaje_warning = mensajeError
              this.mostrarNotificacionAlerta += 1;
              evt.target.disabled = false;
        }
      }).catch(error=>{this.mostrarNotificacionError ++;evt.target.disabled = false;})
      .finally(() =>this.$store.dispatch('auth/loading_http','hidden'))
      evt.target.disabled = false;
    },
    crearNuevoContactosArchivos(evt){
      const id = this.$route.params.id;
      evt = (evt) ? evt : window.event;
      evt.target.disabled = true;
      if (this.formCrearContactosArchivo.archivo != null) {
        let InstFormData = new FormData();
        InstFormData.append('id_grupo' ,id);
        InstFormData.append('archivo' , this.formCrearContactosArchivo.archivo);
        this.$store.dispatch('auth/loading_http','visible');
        this.$http.post(this.$httpUrlMail+'/mail/grupos/integrantes',InstFormData,{
            headers : {'content-type': 'multipart/form-data'},
            withCredentials: false
        }).then(response =>{
          let data = response.data;
          if (data.status == "OK") {
              this.mensaje_success='Contactos Archivo Cargando ...'
              evt.target.disabled = false;
              this.nombre_grupo = '';
              this.mostrarNotificacion ++;
              this.fileInputKey++
              this.formCrearContactosArchivo.archivo = null;
              this.id_process = data.proceso;
              setTimeout(() => { this.getPreviewDataFile(data.proceso)}, 5000);
          }else{
                let mensajeError = '';
                let dataError = response.data;
                for (const key in dataError) {
                  if (Object.hasOwnProperty.call(dataError, key)) {
                    const element = dataError[key];
                    //mensajeError += `${element.mensaje} \n`
                    for (const iterator of element) {
                      mensajeError += `${iterator} \n`
                    }
                  }
                }
                this.mensaje_warning = mensajeError
                this.mostrarNotificacionAlerta += 1;
                evt.target.disabled = false;
          }
        }).catch(error=>{this.mostrarNotificacionError ++;evt.target.disabled = false;}).finally(() =>this.$store.dispatch('auth/loading_http','hidden'))
      }else{
          this.mensaje_warning = "Por favor seleccione un archivo"
          this.mostrarNotificacionAlerta += 1;
          evt.target.disabled = false;
      }
    },
    getPreviewDataFile(id){
      this.$store.dispatch('auth/loading_http','visible');
        this.$http.post(this.$httpUrlMail+'/mail/grupos/integrantes/preview',{
            id_process:id,
        },{ withCredentials: false }).then(response => {
          if (response.data.items != undefined) {
             this.dataPreview["items"] = response.data.items;
             this.dataPreview["min"] = response.data.min;
             this.dataPreview["max"] = response.data.max;
             let objectTemp = [];
             for (const iterator of this.items_form_individual) {
              let key = iterator.field;//this.getNameField(iterator.field);
                objectTemp.push({ key:key,label:key })
             }
             this.dataPreview["id"] = id;
             this.dataPreview["fields"] = objectTemp;
             this.modal_preview_grupo = true;
             this.contadorFlag = 0;
          }else{
            if (this.contadorFlag >= 5) {
                this.contadorFlag = 0;
            }else{
                setTimeout(() => { this.getPreviewDataFile(id)}, 5000);
                this.contadorFlag++;
            }
          }
        }).catch(()=>{
        }).finally(() =>this.$store.dispatch('auth/loading_http','hidden'));
    },
    getProcessDataFile(){
        this.$store.dispatch('auth/loading_http','visible');
        this.$http.post(this.$httpUrlMail+'/mail/grupos/integrantes/process',{
            id_process:this.id_process,
        },{ withCredentials: false }).then(response => {
          let data = response.data;
          if (data.estado == "terminado") {
             this.getInitGrupoIntegrantes();
             this.mensaje_success='Contactos Archivo Cargados Correctamente'
            this.mostrarNotificacion ++;
          }else if(data.estado == "error"){
              this.mensaje_warning = "Error al Cargar Emails Archivo"
              this.mostrarNotificacionAlerta += 1;
              this.$store.dispatch('auth/loading_http','hidden')
          }else{
            setTimeout(() => { this.$store.dispatch('auth/loading_http','visible');this.getProcessDataFile()}, 5000);
          }
        }).catch(()=>{
          this.$store.dispatch('auth/loading_http','hidden')
        })
    },
    closeModal(key){
      switch (key) {
        case 'Close_Upload_Success':
           this.modal_preview_grupo = false;
           setTimeout(() => {this.getProcessDataFile();}, 5000);
          break;
      }
    },
    onChangeArchivoGrupo(event){
      this.formCrearContactosArchivo.archivo = event.target.files[0];
    },
    filtrobusquedalistanegra(event){
      let value = event.target.value;
      if (value.length > 3) {
         this.searchIntegrantes(value);
      }
    },
    getNameField(label){
      let palabras = label.split("_");
      for (let i = 0; i < palabras.length; i++) {
          palabras[i] = palabras[i][0].toUpperCase() + palabras[i].substr(1);
      }
      return palabras.join(" ");
    },
    validationsFieldInput(key,event){
      let value = event.target.value;
      let out = "";
      let filtro = "";
      switch (key) {
        case 'number':
          out = value.replace(/[^0-9]/g, "");
          break;
        case 'decimal':
          let stringValue = value.toString()
          let regex = /^\d*(\.\d{1,2})?$/
          if(!stringValue.match(regex) && value !== '') {
              out = value;
          }
          break;
        default:
          filtro = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890!¡#$%&/()=?¿[]{}.:,;-_+*@>< ñÑ"; //Caracteres validos
          for (var i = 0; i < value.length; i++)
            if (filtro.indexOf(value.charAt(i)) != -1) out += value.charAt(i);
          break;
      }
      return out;
    },
    calcularValidacionFechas(fecha_compara) {
        // Obtén la fecha actual
        var fechaActual = new Date();

        // Crea una fecha que deseas verificar (por ejemplo, '2023-09-14')
        var fechaVerificada = new Date(fecha_compara); // Reemplaza con la fecha que desees verificar

        // Compara la fecha actual con la fecha verificada
        if (fechaVerificada > fechaActual) {
            return false
        } else if (fechaVerificada < fechaActual) {
            return true
        } else {
            return true
        }
    },
    descargarArchivoError (nombre_archivo){
       if (nombre_archivo != '') {
              let nombre = nombre_archivo; 
              var url = this.$httpUrlMail + `/mail/reporte/descargar/general/csv|${nombre}`;
              window.open(url, '_blank');
      }
    },
    validateEmail(email) {
      let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if(email.match(mailformat)){
        return true;
      }
      return false;
    },
    validationsFieldInputEmails(forms){
      let contador = 0;
      for (const iterator of forms) {
          if (iterator.typeField == "email") {
              let valid = this.validateEmail(iterator.value);
              if (valid == false) {
                contador++;
              }
          }
      }
      if (contador > 0) {
        return false;
      }else{
        return true;
        
      }
    }
  },
  mounted(){
    setTimeout(() => {
      this.getInitGrupoIntegrantes();
    }, 100);
  },
  created: function() {
        EventBus.$on('mostrarNotificacion', (item) => {
          console.log(item);
            //this.comments.push(item)
        });
  },
  computed: {
    changeRadioMetodoEnvio(){
      let metodo_envido_checked = 0;
      if (this.metodo_envido_checked_radio == 'individual') {
        metodo_envido_checked = 1;
      }else if(this.metodo_envido_checked_radio == 'archivo'){
        metodo_envido_checked = 2;
      }
      return metodo_envido_checked;
    }
  }
}
</script>
