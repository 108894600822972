<template>
    <div>
        <CModal
            :show.sync="openModal"
            :no-close-on-backdrop="true"
            :centered="false"
            title="Modal title 2"
            size="lg"
            color="success"
            >
            <div>
              <CForm @submit.prevent="editarnuevoGrupo($event)">
                        <CDataTable
                          hover
                          striped
                          :items="dataPreview.items"
                          :fields="dataPreview.fields"
                          :items-per-page="20"
                          :pagination="{ doubleArrows: false, align: 'center'}"
                          @page-change="pageChange"
                        >
                        </CDataTable>
                    <hr>
                    <CButton size="sm" color="danger" type="submit">
                        Insertar Registros
                    </CButton>
                    
                </CForm>
            </div>
            <template #header>
                <h6 class="modal-title">Previsualizacion {{dataPreview.min}} de {{dataPreview.max}} registros en el archivo</h6>
                <CButtonClose @click="closeModal('Close_Upload_Success')" class="text-white"/>
            </template>
            <template #footer>
                <CButton @click="closeModal('Close_Upload_Success')" color="danger">Cerrar</CButton>
                <CButton @click="closeModal('Close_Upload_Success')" color="success" style="display:none;">Accept</CButton>
            </template>
        </CModal>
  <div>
          <CToaster :autohide="5000" class="d-inline-block">
             <template v-for="toast in mostrarNotificacion">
                <CToast
                  :key="'toast'+toast"
                  :show="true"
                  color="success"
                  header="Notificacion Exitosa"
                >
                  {{mensaje_success}}
                </CToast>
              </template>   
              <template v-for="toast1 in mostrarNotificacionAlerta">
                <CToast
                  :key="'toast1'+toast1"
                  :show="true"
                  color="warning"
                  header="Notificacion Alerta"
                >
                  {{mensaje_warning}}
                </CToast>
              </template>
              <template v-for="toast2 in mostrarNotificacionError">
                <CToast
                  :key="'toast2'+toast2"
                  :show="true"
                  color="danger"
                  header="Notificacion Alerta"
                >
                  Error Comunicarse Con Soporte
                </CToast>
              </template>  
          </CToaster>
  </div>
    </div> 
</template>

<script>
export default {
  name: 'PreviewDataGrupo',
  components: {
  },
  props:{
    openModal: Boolean,
    dataPreview:Object
  },
  data () {
    return {
        modal_crear_grupo:true,
        name_group:'',
        list_fields: [],
        typeFields:[
            { value: `string`,label: `Texto`},
            { value: `number`,label: `Numerico`},
            { value: `email`,label: `Email`},
            { value: `decimal`,label: `Decimal 0.00` }
        ],
        mostrarNotificacion: 0,
        mostrarNotificacionAlerta: 0,
        mostrarNotificacionError:0,
        mensaje_warning:'Debe Completar Los Datos Vacio',
        mensaje_success:'Grupo Editado Correctamente.',
    }
  },
  computed:{
  },
  methods: {
    pageChange (val) {
      this.$router.push({ query: { page: val }})
    },
    closeModal(type) {
      this.$emit("close-modal",type);
    },
    editarnuevoGrupo(evt){
      evt = (evt) ? evt : window.event;
      evt.target.disabled = true;
      this.$store.dispatch('auth/loading_http','visible');
      this.$http.post(this.$httpUrlMail+"/mail/grupos/integrantes/execute",{
        id_process:this.dataPreview.id
      },{ withCredentials: false }).then(response =>{
        let data = response.data;
        if (data == "OK") {
            this.mensaje_success=`Insertando registros de archivos.`;
            evt.target.disabled = false;
            this.nombre_grupo = '';
            this.mostrarNotificacion ++;
            this.closeModal('Close_Upload_Success');
        }else{
              let mensajeError = '';
              let dataError = response.data;
              for (const key in dataError) {
                if (Object.hasOwnProperty.call(dataError, key)) {
                  const element = dataError[key];
                  //mensajeError += `${element.mensaje} \n`
                  for (const iterator of element) {
                    mensajeError += `${iterator} \n`
                  }
                }
              }
              this.mensaje_warning = mensajeError
              this.mostrarNotificacionAlerta += 1;
              evt.target.disabled = false;
        }
      }).catch(error=>{this.mostrarNotificacionError ++;evt.target.disabled = false;})
      .finally(() =>this.$store.dispatch('auth/loading_http','hidden'))
    },
  },
  mounted(){
  },
}
</script>

